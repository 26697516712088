import React from 'react';
import { Link } from 'gatsby';

import { Layout, SEO } from '../components';

export default function SuccessPage() {
  return (
    <Layout>
      <SEO title="Success: message sent" />
      <div className="flex items-center justify-center flex-1 w-full mx-auto">
        <div className="max-w-sm p-4 text-center border-2 border-black">
          <h1 className="pt-2 mb-4 text-4xl leading-none text-white font-display">
            Form submitted successfully!
          </h1>
          <p className="font-sans text-white">
            Thank you for your message. We will get back to you as soon as we
            can.
          </p>
          <p className="flex justify-center">
            <Link
              className="inline-block px-6 py-2 -mb-4 text-lg leading-none tracking-wide text-white uppercase bg-black rounded-none hover:bg-gray-900 font-display"
              style={{ transform: `translateY(50%)` }}
              to="/"
            >
              Return to home
            </Link>
          </p>
        </div>
      </div>
    </Layout>
  );
}
